import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "@/services/apiParceiro";
import { useSelector } from "react-redux";
import { dispatch } from "@/store";
import { setReloadWarnings } from "@/store/reducers/warnings";
import { retornaUrlHelp } from "@/services/ambiente";
import {
  Badge,
  Box,
  Container,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LogoFundoEscuro from "@/components/LogoFundoEscuro";
import Profile from "./Profile";
import Grid from "@mui/material/Grid2";
import BotaoText from "@/components/BotaoText";
import MailIcon from "@mui/icons-material/Mail";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UpdateIcon from "@mui/icons-material/Update";
import DialogRelease from "@/components/DialogRelease";

const HeaderContent = () => {
  const { reloadWarnings } = useSelector((state) => state.warnings);
  const navigation = useNavigate();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [abrirUpdates, setAbrirUpdates] = useState(false);

  const inicio = () => {
    navigation("/dashboard");
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const carregaWarnings = async () => {
    const result = await get("secure/alert/resume?limit=5");
    if (result) {
      setWarnings(result);
      dispatch(setReloadWarnings(false));
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const addDots = (title) => {
    return title.length > 70 ? title.substr(0, 70) + "..." : title;
  };

  useEffect(() => {
    carregaWarnings();
  }, [reloadWarnings]);
  useEffect(() => {
    const interval = setInterval(() => {
      carregaWarnings();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!matchesXs && (
        <Box
          sx={{ width: "100%", ml: { xs: 0, md: 1 } }}
          style={{ paddingTop: "6px" }}>
          <LogoFundoEscuro
            onClick={inicio}
            style={{ height: "30px", cursor: "pointer" }}
          />
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={warnings.count} color="success">
          <MailIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Grid container spacing={0} sx={{ p: 2 }}>
          <Grid item size={12}>
            {warnings.count > 0 ? (
              <Container>
                {warnings.alerts.map((alert, index) => (
                  <Typography
                    style={{ cursor: "pointer" }}
                    key={`alert-resume-${index}`}
                    onClick={() => {
                      window.open(
                        `/dashboard/avisos/aviso/${alert.idAlert}`,
                        `_blank`
                      );
                      handleClose();
                    }}>
                    {addDots(alert.title)}{" "}
                  </Typography>
                ))}
              </Container>
            ) : (
              <Typography>Você não possui novos avisos no momento.</Typography>
            )}
          </Grid>
          <Grid item size={12} align="center">
            <BotaoText onClick={() => navigation("avisos")}>
              Ver Todos
            </BotaoText>
          </Grid>
        </Grid>
      </Popover>
      <IconButton
        style={{ marginLeft: "0.3rem" }}
        onClick={() => {
          window.open(`${retornaUrlHelp()}/parceiro/central-ajuda`, "_blank");
        }}
        title="Central de Ajuda">
        <HelpOutlineIcon sx={{ color: "white" }} />
      </IconButton>

      <IconButton
        title="Notas de atualizações"
        onClick={() => {
          setAbrirUpdates(true);
        }}>
        <UpdateIcon sx={{ color: "white" }} />
      </IconButton>
      <Profile />

      {abrirUpdates && <DialogRelease {...{ abrirUpdates, setAbrirUpdates }} />}
    </>
  );
};

export default HeaderContent;
